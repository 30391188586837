// Support both -maestro and -admin host types. EHP-4194.
const engagementHostname =
  window.env.REACT_APP_ENGAGEMENT_BE_HOSTNAME ??
  window.location.hostname.replace("-admin", "-be").replace("-maestro", "-be");
const engagementBackendDomain = `https://${engagementHostname}/api/admin`;

const config = {
  local: {
    domain: "https://plat-dev-engagement-be.platform.evidation.dev/api/admin",
    hostname: "plat-dev-engagement-be.platform.evidation.dev",
  },
  development: {
    domain: engagementBackendDomain,
    hostname: engagementHostname,
  },
  production: {
    domain: engagementBackendDomain,
    hostname: engagementHostname,
  },
  staging: {
    domain: engagementBackendDomain,
    hostname: engagementHostname,
  },
  test: {
    domain: "http://localhost/api/admin",
    hostname: "localhost",
  },
} as const;

export default config;
