import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Space,
  notification,
  Form,
  Typography,
  Input,
  Row,
  Col,
} from "antd";
import { useForm } from "react-hook-form";

import Notifications from "../../../assets/data/notifications";
import { PortalClientError } from "../../../client";
import useUpdateBrand from "../../../client/hooks/brands/useUpdateBrand";
import FormItem from "../../../components/FormItem";
import { defaultHtmlFooter } from "./defaultHtmlFooter";
import { validationSchema } from "./GeneralForm.schema";
import { GeneralFormFields, GeneralFormProps } from "./GeneralForm.types";

const GeneralForm = ({ brand }: GeneralFormProps) => {
  const initialValues = {
    app_name: brand.app_name,
    web_footer_html: brand.web_footer_html ?? defaultHtmlFooter,
  };

  const { setValue, handleSubmit, control } = useForm<GeneralFormFields>({
    defaultValues: initialValues,
    //@ts-expect-error TODO: Resolve these type errors (yup upgrade most likely required.)
    resolver: yupResolver(validationSchema),
  });

  const onReset = () => {
    setValue("web_footer_html", defaultHtmlFooter);
  };

  const { mutateAsync: updateBrand } = useUpdateBrand();

  const onSubmit = async (values: any) => {
    try {
      await updateBrand({
        id: brand.id,
        app_name: values.app_name,
        web_footer_html: values.web_footer_html,
        brand_attributes: {},
      });
      notification.success({
        message: "Success",
        description: Notifications.UpdateCustomization.success,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: `${Notifications.UpdateCustomization.error} ${
          (error as PortalClientError)?.errors?.[0]?.detail
        }`,
      });
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
      <Space direction="vertical" size={24}>
        <Space direction="vertical" size={16}>
          <Typography.Title level={5} style={{ margin: 0 }}>
            App Name
          </Typography.Title>
          <Typography.Text type="secondary">
            App name is displayed on the Login screen (e.g. Log in to App Name).
            It can be a web app or mobile app.
          </Typography.Text>
          <Col style={{ width: 320 }}>
            <FormItem control={control} name="app_name" required>
              <Input maxLength={30} placeholder="Enter App name" />
            </FormItem>
          </Col>
        </Space>
        <Space direction="vertical" size={16} style={{ width: 725 }}>
          <Typography.Title level={5} style={{ margin: 0 }}>
            App Footer
          </Typography.Title>
          <Typography.Text type="secondary">
            App footer is displayed on all screens in the app/web.
          </Typography.Text>
          <Col>
            <Row align="middle" justify="space-between">
              <Typography.Text strong>HTML Editor</Typography.Text>
              <Button onClick={onReset}>Reset to default</Button>
            </Row>
            <FormItem control={control} name="web_footer_html" required>
              <Input.TextArea
                data-testid="general-form-footer-input"
                rows={15}
                style={{
                  fontFamily: "Menlo,Monaco,Consolas,Courier New,monospace",
                  fontSize: 12,
                }}
              />
            </FormItem>
          </Col>
        </Space>
        <Button htmlType="submit" style={{ margin: "0 4px" }} type="primary">
          Save
        </Button>
      </Space>
    </Form>
  );
};
export default GeneralForm;
