import {
  Box,
  Button,
  Chip,
  Heading,
  Image,
  CloseOutlined,
  Text,
  TimeCircleOutlined,
  TimeSensitiveOutlined,
  VStack,
  Banner,
} from "@evidation-shared/eve";
import React from "react";

import Survey from "../Survey";
import { CardProps } from "./Card.types";

const Card: React.FunctionComponent<CardProps> = ({
  body,
  buttons,
  color = "#fffffff",
  duration,
  dismissible,
  expiryDate,
  header,
  image,
  label,
  reward,
  survey,
}) => {
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef();

  React.useEffect(() => {
    // @ts-ignore
    setHeight(ref.current?.clientHeight);
  }, [
    body,
    buttons,
    duration,
    expiryDate,
    header,
    image,
    label,
    reward,
    survey,
  ]);

  return (
    <React.Fragment>
      <Box
        backgroundColor="white"
        maxWidth="343px"
        position="relative"
        ref={ref}
        shadow={0}
      >
        {!image && (
          <div
            style={{
              backgroundImage: `linear-gradient(180deg, ${color} 0%, #ffffff 50%)`,
              opacity: 0.35,
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          />
        )}
        <Box
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          paddingLeft={4}
          paddingRight={4}
          paddingTop={4}
          position={!!image ? "absolute" : "relative"}
          width="100%"
          zIndex={1}
        >
          <Box flexDirection="row">
            {reward && (
              <Chip
                colorScheme="success"
                marginRight={2}
                size="sm"
                variant="solid"
              >
                {reward}
              </Chip>
            )}
            {label && (
              <Chip colorScheme="secondary" size="sm">
                {label}
              </Chip>
            )}
          </Box>
          {dismissible && <CloseOutlined size="22px" />}
        </Box>
        {image && (
          <Image
            alt=""
            height="180px"
            key={image}
            source={image}
            width="100%"
          />
        )}
        <VStack padding={4} space={4}>
          <VStack space={2}>
            {/* @ts-ignore https://achievements.atlassian.net/browse/APLUS-2172 */}
            {header && <Heading size="heading-5">{header}</Heading>}
            {body && (
              <React.Fragment>
                {/* @ts-ignore https://achievements.atlassian.net/browse/APLUS-2172 */}
                <Text color="secondary" fontSize="md" lineHeight="md">
                  {body}
                </Text>
              </React.Fragment>
            )}
          </VStack>
          {survey && Object.keys(survey).length > 0 && (
            <Survey configuration={survey.configuration} type={survey.type} />
          )}
          {(duration || expiryDate) && (
            <Box alignItems="center" flexDirection="row">
              {duration && [
                <TimeCircleOutlined marginRight={1.5} size="20px" />,
                // @ts-ignore https://achievements.atlassian.net/browse/APLUS-2172
                <Text color="secondary" fontSize="sm" lineHeight="sm">
                  {duration}
                </Text>,
              ]}
              {duration && expiryDate && (
                <Box
                  backgroundColor="gray.900"
                  borderRadius="50%"
                  height="4px"
                  marginX={3}
                  width="4px"
                />
              )}
              {expiryDate && [
                <TimeSensitiveOutlined marginRight={1.5} size="20px" />,
                // @ts-ignore https://achievements.atlassian.net/browse/APLUS-2172
                <Text color="secondary" fontSize="sm" lineHeight="sm">
                  {expiryDate}
                </Text>,
              ]}
            </Box>
          )}
          {buttons && buttons.length > 0 && (
            <Button.Group direction="row" space={3}>
              {buttons.map((button) => (
                <Button
                  flex={1}
                  key={button.description}
                  onPress={() => button.onPress?.()}
                  size="md"
                  variant={buttons.length > 1 ? "subtle" : "solid"}
                >
                  {button.description}
                </Button>
              ))}
            </Button.Group>
          )}
        </VStack>
      </Box>
      {height > 480 && (
        <Banner
          borderRadius={2}
          colorScheme="warning"
          description={`Offer cards should not exceed 480 pixels in height. This offer is currently ${height} pixels in height.`}
          marginTop={2}
        />
      )}
    </React.Fragment>
  );
};

export default Card;
